import React from 'react';
import styled from 'styled-components';
import {
  BUTTON_STYLE,
  BREAKPOINT,
  PADDING,
  COLOR,
  ALIGN,
  MARGIN
} from '@latitude/core/utils/constants';
import { Text } from '@latitude/text';
import SvgInline from '@latitude/svg-inline';
import { Heading5 } from '@latitude/heading';
import { Link } from '@latitude/link';

export default () => {
  const href = '/loans/loan-repayment-calculator/';
  const OuterBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 24px 16px;
    background-image: url(${require('../../images/bg-calculators.svg')});
    background-position: center;
    margin-top: -${MARGIN.M48};
    @media (min-width: ${BREAKPOINT.LG}) {
      padding: 72px 0;
    }
    @media (max-width: ${BREAKPOINT.MD}) {
      margin-top: 0;
    }
  `;

  const Tile = styled.div`
    width: 100%;
    max-width: 343px;
    padding: ${PADDING.P32};
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${COLOR.WHITE};
  `;

  return (
    <OuterBox>
      <Tile>
        <SvgInline name="icon-calc-pl-branded" />
        <Heading5
          align={ALIGN.CENTER}
          color={COLOR.BLACK}
          marginTop={MARGIN.M24}
          marginBottom={MARGIN.M16}
        >
          Personal loan <br />
          repayment calculator
        </Heading5>
        <Text
          align={ALIGN.CENTER}
          color={COLOR.BLACK}
          marginBottom={MARGIN.M56}
        >
          What will my repayments be?
        </Text>
        <Link
          href={href}
          button={BUTTON_STYLE.TERTIARY}
          target="_self"
          trackId="personal-loan-repayment-calculator-lets-calculate"
          trackEventData={{
            location: 'Personal loan repayment calculator'
          }}
          trackProductId={['PLNZLF-WEB']}
        >
          Let&apos;s calculate
        </Link>
      </Tile>
    </OuterBox>
  );
};
