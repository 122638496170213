// @flow

import React, { useState, useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { AccordionSidebar } from '@latitude/accordion';
import { ImportantInformation } from '@latitude/important-information';
import { Box } from '@latitude/box';
import { FeaturesSlider } from '@latitude/features-slider';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { StickyCta } from '@latitude/sticky-cta';
import { Heading4 } from '@latitude/heading';
import Button from '../../components/Button/Button';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { COLOR, ALIGN } from '@latitude/core/utils/constants';
import Layout from '../../components/layout';
import PageData from '../../data/pages/personal-loan.json';
import footerData from '../../data/json/footer.json';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import LoanRates from '../../components/LoanRates/LoanRates1';
import CalculatorTile from '../../components/Calculators/CalculatorTile';
import { featuresSliderIE11Fix } from '../../utils/stylesUtils';
import { LOAN_PURPOSE } from '../../utils/constants';
import EstimateRateWidgetSection from '../../components/EstimateRateWidgetSection/EstimateRateWidgetSection1';
import useApplyUrl from '../../hooks/useApplyUrl';
import SoftQuoteContext from '../../context/SoftQuoteContext';
import faqsLoans from './_faqs';
import HowToApplyEligibility from '../../components/HowToApplyEligibility/HowToApplyEligibility1';
import FeesAndCharges from './_LoanFeesCharges';
import heroImage from '../../images/hero-personal-loans.webp';
import SvgInline from '@latitude/svg-inline';
import { PageContext } from '../../context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { selectDataSourceForAlertBanner } from '@/utils/helpers';
import { useSetState } from '@/utils/hooks';
import PageHeaderSection from '@/components/PageLayout/PageHeaderSection';
import { getAccordionSidebarData } from "../../utils/helpers";

/*
 * Personal Loan Page
 */
const PersonalLoanPage = () => {
  const [isNavSticky, setIsNavSticky] = useState(false);
  const { applyUrl, setApplyUrl } = useApplyUrl();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  const featureSliderData = state?.featureSliderData || [
    {
      heading: 'Why choose a Gem Loan?',
      featureCards: PageData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  return (
    <Layout noMetaKeywords customFooter={footerData} hasStickyNav>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer navigation-spacer--hasStickyNav personal-loan-page">
        <Box backgroundColor={COLOR.GREY6}>
          <Helmet>
            <link
              rel="canonical"
              href="https://www.gemfinance.co.nz/loans/personal-loans/"
            />
            <title>
              Personal Loans NZ | Fixed Interest Rates | Gem by gem finance
            </title>
            <meta
              name="description"
              content="Need a Personal Loan in New Zealand for a new car, home renovation, travel or consolidate debt? With Gem Finance personal loan get a quote in under 2 mins."
            />
          </Helmet>
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              pageImage={heroImage}
              title="Personal Loans"
              subTitle={
                <>
                  Every second counts when you’ve got big plans. Apply for a Gem
                  Personal Loan online in just 7 minutes
                  <span class="conditional-text">
                    {' '}
                    and get a response to your application in 60 seconds
                  </span>
                  .
                </>
              }
            >
              <div className="row text-white HeroContent__insettext">
                <div className="col-6 col-md-5 text-left">
                  <HomeButton
                    href={applyUrl}
                    trackId="personal-loans-button"
                    trackEventData={{
                      label: 'Get rate estimate',
                      location: 'Hero',
                      category: 'button-link'
                    }}
                    trackProductId={['PLNZLF-WEB']}
                    className="button--primary"
                  >
                    Get rate estimate
                  </HomeButton>
                </div>
                <div className="col-1 p-0 text-center">
                  <SvgInline name="stopwatch" />
                </div>
                <div className="col-5 col-md-6 text-left">
                  Check your interest rate in 2 minutes with no impact to your
                  credit score.
                </div>
              </div>
              <div className="row text-white HeroContent__insetdisclaimer">
                <div className="col-12 mt-2">
                  Credit and lending criteria, and fees apply.
                </div>
              </div>
            </PageHeaderSection>
          )}

          {/* Start: Hero with ribbon / no image */}
          <SoftQuoteContext.Provider value={{ applyUrl, setApplyUrl }}>
            <div className="d-lg-none d-xl-none">
              <StickyCta
                href={applyUrl}
                trackId="sticky-get-started"
                text="Get rate estimate"
              />
            </div>
            {/* End: Hero with ribbon / no image */}

            <div className="d-none d-lg-block">
              <StickyNavigation
                items={PageData.nav}
                phoneNumber={PageData.content.phoneNumber}
                isSticky={isNavSticky}
                onStickyNavStateChange={setIsNavSticky}
                ctaHref={applyUrl}
                ctaTarget="_self"
                ctaText="Get rate estimate"
                offsetElem="[data-sticky-navigation-offset]"
                trackId="sticky-nav-get-started"
                trackEventData={{
                  category: 'cta',
                  action: 'quote-link',
                  label: 'Get rate estimate',
                  location: 'Sticky Navigation'
                }}
                trackProductId={['PLNZLF-WEB']}
                {...state?.inPageNavData?.[0]}
              />
            </div>

            <EstimateRateWidgetSection
              purpose={LOAN_PURPOSE.SELECT_PURPOSE}
              trackProductId={['PLNZLF-WEB']}
            />
          </SoftQuoteContext.Provider>

          <div
            css="
              position: relative;
              z-index: 1;"
          >
            <LoanRates
              isBranded
              rateBoxType="personalSym"
              rateBoxType2="personalUnsecuredSym"
              fees={PageData.content.loanDetails1}
              // padBottom="pb-10"
            />
          </div>
          <FeesAndCharges data={PageData.content.fees} />

          <CalculatorTile />
          {featureSliderData[0] && (
            <FeaturesSlider
              id="why-us"
              className="why-choose bg-f8f8f8"
              heading={featureSliderData[0].heading}
              subheading={featureSliderData[0].description}
              data={featureSliderData[0].featureCards}
              css={featuresSliderIE11Fix}
            />
          )}
          <HorizontalRule />

          <HowToApplyEligibility />

          <Box id="faq">
            <div data-contentful={state?.faqData?.[0]?.contentfulID}>
              <Box.Section>
                <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
                  {state?.faqData?.[0]?.title || 'Frequently asked questions'}
                </Heading4>
                <AccordionSidebar
                  data={
                    getAccordionSidebarData(state?.faqData?.[0]?.data) ||
                    faqsLoans.loanPersonal1
                  }
                />
              </Box.Section>
            </div>
          </Box>

          <ImportantInformation
            data={require('../../data/pages/personal-loan.json')}
            sectionOneColummClasses="col-10 offset-1"
            {...state?.importantInfoData?.[0]}
          />
        </Box>
      </main>
    </Layout>
  );
};

const HomeButton = styled(Button)`
  && {
    padding: 15px 45px;
    background-color: #21a637;
    color: #fff !important;
    white-space: nowrap;
    font-size: 22px;
  }

  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;
export default PersonalLoanPage;
